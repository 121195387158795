import { useQuery } from "react-query";
import { IRelationshipParameterTag } from "../../interfaces";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";

interface IGetAllSolutionParamTagsRes {
  count: number;
  data: IRelationshipParameterTag[];
}

const getAllSolutionParamTags = async () => {
  const response = await axiosInstance.get(
    "/assets/relationship-parameters/tags/"
  );
  return response.data;
};

const useGetAllSolutionParamTags = () => {
  const response = useQuery<IGetAllSolutionParamTagsRes, Error>(
    [queryKeys.solutionParameterTags],
    () => getAllSolutionParamTags()
  );
  return { ...response };
};

export default useGetAllSolutionParamTags;
